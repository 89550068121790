import logo from "./logo.svg";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import Router from "./routes";
import { ConfirmProvider } from "material-ui-confirm";
import { createGlobalStyle } from "styled-components";
import ThemeConfig from "./theme";
import { SnackbarProvider } from "./context/SnackbarContext";

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(240, 192 , 0, 0.4);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

function App() {
  return (
    <ThemeConfig>
      <SnackbarProvider>
        <AuthProvider>
          <ConfirmProvider>
            <GlobalStyle />
            <Router />
          </ConfirmProvider>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeConfig>
  );
}

export default App;
