import { useEffect, useState } from "react";
import { dataFetch } from "../../utils/data-fetch";
import { useAuth } from "../../context/AuthContext";
import MakeFullScreen from "../MakeFullScreen";
import moment from "moment";
import { Box, Stack, TablePagination, Typography } from "@mui/material";
import Label from "../Label";

export const TransactionHistoryModal = ({ isOpen, onClose }) => {
  const utcOffset = 330 * 60 * 1000;

  const getDateStringSync = (time) => {
    const newTime = new Date(time + utcOffset);

    const st = moment(newTime).utc();
    const a = st.format("DD-MM-YY");

    return a;
  };

  const [transactionHistory, setTransactionHistory] = useState(null);
  const [error, setError] = useState(null);
  const { getAccessToken } = useAuth();
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getTransactionHistory = async () => {
    const url = `/api/extrasales/b2bact/txns/query?skip=${
      page * 5
    }&limit=5&sort=d`;
    const ret = await dataFetch(url, await getAccessToken());
    if (ret.data) {
      console.log(ret.data);

      setTransactionHistory(ret.data);
    } else {
      setError(ret.error || "Failed to fetch data");
    }
  };

  useEffect(() => {
    getTransactionHistory();
  }, [page]);

  return (
    <MakeFullScreen
      open={isOpen}
      onClose={onClose}
      title={"Transaction History"}
      fullScreen={true}
    >
      {transactionHistory ? (
        <Stack spacing={1}>
          {transactionHistory.map((txn, index) => (
            <Box
              key={txn._id}
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3, // Increased padding for better spacing
                m: 2,
                borderRadius: 3, // Increased border radius for a softer look
                bgcolor: index % 2 === 0 ? "grey.50" : "white",
                boxShadow: 2, // Slightly stronger shadow for more emphasis
                transition: "0.3s",
                "&:hover": {
                  bgcolor: "grey.100",
                  boxShadow: 4, // Increased hover shadow effect
                },
              }}
            >
              <Stack direction="column" justifyContent="center" width={"100%"}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <img
                    src={
                      txn?.entity === "salebill"
                        ? "/static/bill.png"
                        : "/static/payment.png"
                    }
                    alt="transaction"
                    style={{ width: 35, height: 35, borderRadius: 20 }} // Increased icon size for better visibility
                  />
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 600, color: "success.main" }}
                  >
                    ₹ {txn?.amount}
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: "text.primary",
                      textTransform: "uppercase",
                    }}
                  >
                    {txn?.entity === "salebill" ? (
                      <Label color="success" variant="ghost">
                        {txn?.bill?.items[0]?.name}
                      </Label>
                    ) : txn?.status === "success" ? (
                      <Label color="success" variant="ghost">
                        {txn?.status.toUpperCase()}
                      </Label>
                    ) : (
                      <Label color="error" variant="ghost">
                        {txn?.status.toUpperCase()}
                      </Label>
                    )}
                  </Typography>

                  <Stack direction="column" alignItems="flex-end">
                    {txn?.entity === "salebill" && (
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 500, color: "text.secondary" }}
                      >
                        {txn?.bill?.items[0]?.quantity} KG
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 500, color: "text.secondary" }}
                    >
                      {getDateStringSync(txn?.date)}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          ))}

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={5}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5]}
            />
          </Box>
        </Stack>
      ) : (
        <p>Loading...</p>
      )}
      {/* {transactionHistory ? (
        <table>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Payment Method</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {transactionHistory.map((txn) => (
              <tr key={txn._id}>
                <td>{txn.amount}</td>
                <td>{txn.method}</td>
                <td>{txn.status}</td>
                <td>{getDateStringSync(txn.date)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Loading...</p>
      )} */}
    </MakeFullScreen>
  );
};
