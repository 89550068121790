import { Form, FormikProvider, useFormik } from "formik";
import MakeFullScreen from "../MakeFullScreen";
import { Card, InputAdornment, Typography } from "@mui/material";
import { Alert, Stack, TextField } from "@mui/material";
import { dataFetch, dataPost } from "../../utils/data-fetch";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { LoadingButton } from "@mui/lab";
import { DateSelector } from "../../utils/date-selector";

export const AddNewOrderModal = ({ open, party, handleM1Close }) => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { getAccessToken } = useAuth();
  const [selectedDate, setSelectedDate] = useState(Date.now() + 86400000);
  const [rate, setRate] = useState(null);
  const [price, setPrice] = useState(null);

  const getProductDeals = async () => {
    const url = `/api/extrasales/b2bact/products/${party._id}`;
    const ret = await dataFetch(url, await getAccessToken());
    if (ret.data) {
      setProducts(ret.data);
    } else {
      setError(ret.error);
    }
  };

  const handleQuantityChange = (e) => {
    const quantity = e.target.value;
    formik.setFieldValue("quantity", quantity);

    if (selectedProduct) {
      let rateToSet = selectedProduct.default_rate;

      selectedProduct.conditions.forEach((condition) => {
        if (
          quantity >= condition.quantity_min &&
          quantity <= condition.quantity_max
        ) {
          rateToSet = condition.rate;
        }
      });
      setRate(rateToSet);
      setPrice(rateToSet * quantity);
      formik.setFieldValue("rate", rateToSet); // Set rate in formik state
    }
  };

  // Submit the order
  const AddOrder = async (values) => {
    const url = `/api/extrasales/b2bact/orders/new`;
    const data = {
      deal: selectedProduct._id,
      product: selectedProduct.product.name,
      product_id: selectedProduct.product._id,
      quantity: values.quantity,
      date: selectedDate.valueOf(),
    };

    const ret = await dataPost(url, await getAccessToken(), data);
    if (ret.data) {
      formik.resetForm();
      selectedProduct(null);
      handleM1Close();
    } else {
      setError(ret.error);
    }
  };

  useEffect(() => {
    if (party) {
      getProductDeals();
    }
  }, [party]);

  // Validation schema
  const DataSchema = Yup.object().shape({
    quantity: Yup.number().required("Quantity is required"),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      quantity: "",
      amount: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await AddOrder(values);
      handleM1Close();
      formik.resetForm();
      setSelectedProduct(null);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values } =
    formik;

  return (
    <MakeFullScreen
      open={open}
      onClose={() => {
        handleM1Close();
        setSelectedProduct(null);
        formik.resetForm();
      }}
      title={`Order Product`}
      maxWidth={"sm"}
    >
      {selectedProduct !== null ? (
        <Stack direction="column" spacing={2} p={2}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {/* Quantity Input */}
                <TextField
                  fullWidth
                  type="number"
                  label="Quantity"
                  {...getFieldProps("quantity")}
                  onChange={handleQuantityChange}
                  error={Boolean(touched.quantity && errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                />

                {/* Price Display */}
                <TextField
                  fullWidth
                  type="number"
                  label="Price"
                  value={price !== null ? price : ""}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />

                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Rate"
                    value={rate !== null ? rate : ""}
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />

                  <DateSelector
                    value={selectedDate}
                    onChange={setSelectedDate}
                  />
                </Stack>
              </Stack>

              {/* Submit Button */}
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Order Now
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Stack>
      ) : (
        <Stack direction="column" spacing={2} p={2}>
          <Typography variant="body1">Select a product to continue</Typography>
          {Array.isArray(products) && products.length > 0 ? (
            products.map((item) => (
              <Card
                key={item.product._id}
                sx={{
                  p: 2,
                  cursor: "pointer",
                  borderRadius: 3,
                  boxShadow: 3,
                  transition: "0.3s",
                  "&:hover": {
                    boxShadow: 6,
                    transform: "scale(1.02)",
                  },
                }}
                onClick={() => setSelectedProduct(item)}
              >
                <Stack direction="row" spacing={4}>
                  <img
                    src={item?.product?.images[0] || "/static/icon2.png"}
                    alt="product"
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: "40%",
                    }}
                  />
                  <Stack direction="column">
                    <Typography variant="h6">{item.product.name}</Typography>
                    <Typography variant="body1">{item.product.bio}</Typography>
                  </Stack>
                </Stack>
              </Card>
            ))
          ) : (
            <Typography variant="body1">No products available</Typography>
          )}
        </Stack>
      )}

      {/* Error Alert */}
      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </MakeFullScreen>
  );
};
