import {
  AppBar,
  Button,
  Dialog,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import React, { forwardRef } from "react";
import { CloseRounded } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MakeFullScreen({
  open,
  onClose,
  maxWidth,
  title,
  header,
  children,
  fullScreen,
}) {
  return (
    <Dialog
      maxWidth={maxWidth || "lg"}
      scroll="body"
      fullWidth
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"white"}
          >
            {title || "-"}
          </Typography>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            {header}
            <Button
              variant="contained"
              color="error"
              onClick={onClose}
              sx={{ ml: 1 }}
            >
              <CloseRounded />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}
