import { Navigate, useRoutes } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import B2BLogin from "./pages/login/login";
import B2bLayout from "./layout/b2b";
import B2bDashBoard from "./pages/home";
import DashboardLayout from "./pages/dashboard";
import LogoOnlyLayout from "./pages/LogoOnlyLayout";

export default function Router() {
  const { currentUser } = useAuth();

  const rts = useRoutes([
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "*", element: <Navigate to="/dashboard" /> },
      ],
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "", element: <Navigate to="/dashboard/home" replace /> },
        { path: "home", element: <B2bDashBoard /> },
      ],
    },
  ]);

  const rts2 = useRoutes([
    { path: "*", element: <Navigate to="/login" replace /> },
    { path: "/login", element: <B2BLogin /> },
  ]);

  return currentUser ? rts : rts2;
}
