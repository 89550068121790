import { WhatsApp } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  Stack,
  Divider,
  IconButton,
} from "@mui/material";
import MHidden from "../@material-extend/MHidden";
import { useState } from "react";
import AboutUs from "./AboutUs";

const Footer = () => {
  const [openAboutModal, setOpenAboutModal] = useState(false);

  const handleClose = () => {
    setOpenAboutModal(false);
  };

  return (
    <>
      <MHidden width="mdUp">
        <Box
          sx={{
            width: "100%",
            bottom: 0,
            color: "white",
            bgcolor: "#FFEB3B",
            py: 4, // Increased padding for more space
            px: 3,
            textAlign: "center", // Center the content
            boxShadow: 2,
          }}
        >
          <Stack spacing={3} direction="column" alignItems="center">
            <img
              src="/static/logo.png"
              alt="product"
              style={{
                width: 180,
                height: 60,
                filter: "grayscale(100%) brightness(0%) contrast(200%)",
              }}
            />

            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: "textPrimary" }}
            >
              Hyperlocal Plastic-Free Raw and Fresh Milk Delivery
            </Typography>

            <Divider sx={{ width: "80%" }} />
            <Stack spacing={2} direction="column" alignItems="center">
              {/* <Typography variant="h5" sx={{ fontWeight: 500 }}>
                Useful Links
              </Typography> */}

              {/* <Divider sx={{ width: "40%" }} /> */}

              <Stack direction="column">
                <Button
                  sx={{ textTransform: "none", fontWeight: 400 }}
                  onClick={() => {
                    setOpenAboutModal(true);
                  }}
                >
                  About Us
                </Button>

                <Button
                  sx={{ textTransform: "none", fontWeight: 400 }}
                  // onClick={() => {
                  //   window.open("/contact", "_self");
                  // }}
                >
                  Contact Us
                </Button>

                <Button
                  sx={{ textTransform: "none", fontWeight: 400 }}
                  // onClick={() => {
                  //   window.open("/faq", "_self");
                  // }}
                >
                  FAQ
                </Button>
              </Stack>
            </Stack>

            <Divider sx={{ width: "80%" }} />

            <Stack direction="column" spacing={2} alignItems="center">
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Have an Issue?
              </Typography>

              <Button
                variant="contained"
                startIcon={<WhatsApp />}
                sx={{
                  backgroundColor: "#25D366", // WhatsApp green
                  color: "white",
                  borderRadius: 2, // Rounded button
                  px: 3,
                  py: 1.5,
                  "&:hover": {
                    backgroundColor: "#1EBE57", // Darker green on hover
                  },
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Chat on WhatsApp!
                </Typography>
              </Button>
            </Stack>

            <Divider sx={{ width: "80%" }} />

            <Typography variant="body2" sx={{ fontWeight: 400, mt: 2 }}>
              Milkvilla©2024, All Rights Reserved
            </Typography>
          </Stack>
        </Box>
      </MHidden>
      <MHidden width="smDown">
        <Box
          sx={{
            width: "100%",
            bottom: 0,
            color: "white",
            bgcolor: "#FFEB3B",
            py: 4, // Increased padding for more space
            px: 5,
            textAlign: "center",
            boxShadow: 2,
          }}
        >
          <Stack
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack spacing={2} direction="column" alignItems="flex-start">
              <img
                src="/static/logo.png"
                alt="product"
                style={{
                  width: 300, // Adjusted width for desktop
                  height: 70,
                  filter: "grayscale(100%) brightness(0%) contrast(200%)",
                }}
              />

              <Typography
                variant="h6"
                sx={{
                  fontWeight: 300,
                  color: "textPrimary",
                  textAlign: "left",
                }}
              >
                Hyperlocal Plastic-Free Raw and Fresh Milk Delivery
              </Typography>
            </Stack>

            <Stack spacing={2} direction="column" alignItems="center">
              {/* <Typography variant="h5" sx={{ fontWeight: 500 }}>
                Useful Links
              </Typography>

              <Divider sx={{ width: "40%" }} /> */}

              <Stack direction="column">
                <Button
                  sx={{ textTransform: "none", fontWeight: 300 }}
                  onClick={() => {
                    setOpenAboutModal(true);
                  }}
                >
                  About Us
                </Button>

                <Button
                  sx={{ textTransform: "none", fontWeight: 400 }}
                  // onClick={() => {
                  //   //window.open("/contact", "_self");
                  // }}
                >
                  Contact Us
                </Button>

                <Button
                  sx={{ textTransform: "none", fontWeight: 400 }}
                  onClick={() => {
                    //window.open("/faq", "_self");
                  }}
                >
                  FAQ
                </Button>
              </Stack>
            </Stack>

            <Stack direction="column" spacing={2} alignItems="center">
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Have an Issue?
              </Typography>

              <Button
                variant="contained"
                startIcon={<WhatsApp />}
                sx={{
                  backgroundColor: "#25D366", // WhatsApp green
                  color: "white",
                  borderRadius: 2, // Rounded button
                  px: 3,
                  py: 1.5,
                  "&:hover": {
                    backgroundColor: "#1EBE57", // Darker green on hover
                  },
                }}
                onClick={() => {
                  window.open("https://wa.me/919006890881", "_blank");
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Chat on WhatsApp!
                </Typography>
              </Button>
            </Stack>
          </Stack>

          {/* Divider after main content */}
          <Divider sx={{ my: 3 }} />

          {/* Footer Text */}
          <Typography variant="body2" sx={{ fontWeight: 400, mt: 2 }}>
            Milkvilla©2024, All Rights Reserved
          </Typography>
        </Box>
      </MHidden>

      <AboutUs onOpen={openAboutModal} onClose={handleClose} />
    </>
  );
};

export default Footer;
