import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

// material
import { styled } from "@mui/material/styles";
import { Stack, Box, Link, Drawer, Typography, Avatar } from "@mui/material";

// components
// import NavSection from "../../components/NavSection";


// import { getConfig } from "./SidebarConfig";
import NavSection from "../../components/NavSection";
import { getConfig } from "./SidebarConfig";
import { useAuth } from "../../context/AuthContext";
import MHidden from "../../components/@material-extend/MHidden";
import Logo from "../../components/Logo";

// ----------------------------------------------------------------------

const photoUrl = "/static/avatar_default.jpg";
const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme, drawerHidden }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: (drawerHidden === false && DRAWER_WIDTH) || 0,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  drawerHidden,
}) {
  //const { pathname } = useLocation();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = (
    <Box>
      <Box sx={{ px: 2.5, py: 2 }}>
        <Stack direction="row" alignItems="center" color="black">
          <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            <Logo />
          </Box>
          <Box mt={2.5} ml={1}>
            "Dashboard"
          </Box>
        </Stack>
      </Box>

    </Box>
  );
  console.log(currentUser);
  

  const FeatureList = <NavSection navConfig={getConfig()} />;
  console.log("getconfig", getConfig());
  

  return (
    <RootStyle drawerHidden={drawerHidden}>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
          {FeatureList}
        </Drawer>
      </MHidden>

    </RootStyle>
  );
}
