import { Button, Divider, Grid, Grid2, Stack, Typography } from "@mui/material";
import MHidden from "../@material-extend/MHidden";
import { dataFetch } from "../../utils/data-fetch";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";
import MakeFullScreen from "../MakeFullScreen";
import { TransactionHistoryModal } from "./TransactionHistoryModal";

export const PaymentComp = () => {
  const [accountDetails, setAccountDetails] = useState([]);
  const { getAccessToken } = useAuth();
  const [accountDetailsModal, setAccountDetailsModal] = useState(false);
  const [openTransactionHistoryModal, setopenTransactionHistoryModal] =
    useState(false);
  const [error, setError] = useState(null);
  const handleAccountModalClose = () => {
    setAccountDetailsModal(false);
  };

  const handleTransactionHistoryModalClose = () => {
    setopenTransactionHistoryModal(false);
  };

  const handlePayDue = async () => {
    const url = `/api/extrasales/b2b/payments/bank-transfer`;

    const ret = await dataFetch(url, await getAccessToken());

    if (ret.success) {
      setAccountDetails(ret.data);
    } else {
      setError(ret.error || "Failed to fetch data");
    }
  };

  return (
    <>
      <MHidden width="smDown">
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color="success"
            sx={{
              width: "100%",
              padding: "12px 0",
              bgcolor: "white",
              color: "black",
              "&:hover": {
                bgcolor: "grey.300",
              },
            }}
            onClick={() => {
              handlePayDue();
              setAccountDetailsModal(true);
            }}
          >
            Pay Due
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "100%", // Full width for mobile
              padding: "12px 0", // Added vertical padding
              borderColor: "white",
              color: "white",
              "&:hover": {
                borderColor: "grey.300",
              },
            }}
            onClick={() => {
              setopenTransactionHistoryModal(true);
            }}
          >
            Transaction History
          </Button>
        </Stack>
      </MHidden>
      <MHidden width="smUp">
        <Stack spacing={1} direction="column" p={2}>
          <Button
            variant="contained"
            color="success"
            sx={{
              width: "100%",
              padding: "12px 0",
              bgcolor: "white",
              color: "black",
              touchAction: "manipulation",
              "&:hover": {
                bgcolor: "grey.300",
              },
              "&:focus": {
                outline: "none",
                bgcolor: "grey.200",
              },
            }}
            onClick={() => {
              handlePayDue();
              setAccountDetailsModal(true);
            }}
          >
            Pay Due
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "100%",
              padding: "12px 0",
              borderColor: "white",
              color: "white",
              touchAction: "manipulation",
              "&:hover": {
                borderColor: "grey.300",
              },
              "&:focus": {
                outline: "none",
                borderColor: "grey.200",
              },
            }}
            onClick={() => {
              setopenTransactionHistoryModal(true);
            }}
          >
            Transaction History
          </Button>
        </Stack>
      </MHidden>
      {openTransactionHistoryModal && (
        <TransactionHistoryModal
          isOpen={openTransactionHistoryModal}
          onClose={handleTransactionHistoryModalClose}
        />
      )}
      {accountDetailsModal && (
        <MakeFullScreen
          open={accountDetailsModal}
          onClose={handleAccountModalClose}
          fullScreen={true}
          title={"Account Details"}
        >
          <Stack
            spacing={3}
            direction="column"
            sx={{
              padding: { xs: 2, md: 4 },
              bgcolor: "background.default",
              borderRadius: 3,
              boxShadow: 4,
              maxWidth: "100%",
              margin: "auto",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "1.2rem", md: "1.5rem" },
                fontWeight: 700,
                textAlign: "center",
                color: "primary.main",
                textTransform: "uppercase",
                letterSpacing: 1,
                paddingBottom: 2,
                borderBottom: "2px solid",
                borderColor: "primary.light",
              }}
            >
              Bank Accounts
            </Typography>

            {accountDetails.length > 0 ? (
              <Grid2 container spacing={3}>
                {accountDetails.map((item, index) => (
                  <Grid2 key={index} item xs={12} md={6} justifyContent={"space-between"}>
                    <Stack
                      spacing={2}
                      sx={{
                        padding: 2,
                        border: "1px solid",
                        borderColor: "grey.300",
                        borderRadius: 2,
                      }}
                    >
                      {/* Bank Name */}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: 600,
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          Bank Name
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "text.secondary" }}
                        >
                          {item?.bank_account?.bank}
                        </Typography>
                      </Stack>
                      <Divider sx={{ bgcolor: "grey.300" }} />

                      {/* Account Name */}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          Account Name
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "text.secondary" }}
                        >
                          {item?.bank_account?.acc_name}
                        </Typography>
                      </Stack>
                      <Divider sx={{ bgcolor: "grey.300" }} />

                      {/* Account Number */}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          Account Number
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "text.secondary" }}
                        >
                          {item?.bank_account?.acc_no}
                        </Typography>
                      </Stack>
                      <Divider sx={{ bgcolor: "grey.300" }} />

                      {/* IFSC Code */}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          IFSC Code
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "text.secondary" }}
                        >
                          {item?.bank_account?.ifsc}
                        </Typography>
                      </Stack>
                      <Divider sx={{ bgcolor: "grey.300" }} />

                      {/* Branch */}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          Branch
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "text.secondary" }}
                        >
                          {item?.bank_account?.branch}
                        </Typography>
                      </Stack>
                      <Divider sx={{ bgcolor: "grey.300" }} />

                      {/* UPI ID */}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          UPI ID
                        </Typography>

                        <Typography
                          variant="body1"
                          sx={{ color: "text.secondary" }}
                        >
                          {item?.bank_account?.upi}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid2>
                ))}
              </Grid2>
            ) : (
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                {error || "No account details found"}
              </Typography>
            )}

            <Typography
              variant="caption"
              sx={{
                textAlign: "center",
                marginTop: 3,
                color: "text.disabled",
              }}
            >
              All details are securely stored.
            </Typography>
          </Stack>
        </MakeFullScreen>
      )}
    </>
  );
};
