import {
    KeyboardArrowLeftRounded,
    KeyboardArrowRightRounded,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRightRounded,
  } from "@mui/icons-material";
  
  import { IconButton, Stack } from "@mui/material";
  
  import {
    LocalizationProvider,
    MobileDatePicker,
    MobileDateTimePicker,
  } from "@mui/x-date-pickers";
  import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
  import moment from "moment";
  
  export const TWENTY4HOURS = 24 * 60 * 60 * 1000  
  export const MonthSelector = ({ value, onChange }) => {
    return (
      <Stack direction={"row"} alignItems={"center"}>
        <IconButton
          sx={{ display: "inline-flex" }}
          onClick={() => {
            const newD = moment(value).subtract(1, "month").valueOf();
            onChange(newD);
          }}
        >
          <KeyboardArrowLeftRounded />
        </IconButton>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-in"}>
          <MobileDatePicker
            views={["month"]}
            value={moment(value)}
            onAccept={(newValue) => {
              if (newValue) {
                const d1 = newValue.valueOf();
                if (value !== d1) onChange(d1);
              }
            }}
            sx={{ maxWidth: "130px" }}
            closeOnSelect={true}
            format="MMM YYYY"
          />
        </LocalizationProvider>
        <IconButton
          sx={{ display: "inline-flex" }}
          onClick={() => {
            const newD = moment(value).add(1, "month").valueOf();
            onChange(newD);
          }}
        >
          <KeyboardArrowRightRounded />
        </IconButton>
      </Stack>
    );
  };
  
  export const DateTimeSelector = ({ value, onChange }) => {
    return (
      <Stack direction={"row"} alignItems={"center"}>
        <IconButton
          sx={{ display: "inline-flex" }}
          onClick={() => {
            onChange(value - TWENTY4HOURS);
          }}
        >
          <KeyboardArrowLeftRounded />
        </IconButton>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-in"}>
          <MobileDateTimePicker
            value={moment(value)}
            onAccept={(newValue) => {
              if (newValue) {
                const d1 = newValue.valueOf();
                if (value !== d1) onChange(d1);
              }
            }}
            sx={{ maxWidth: "200px", minWidth: "130px" }}
            closeOnSelect={true}
            format="DD/MM/YYYY hh:mm A"
          />
        </LocalizationProvider>
        <IconButton
          sx={{ display: "inline-flex" }}
          onClick={() => {
            onChange(value + TWENTY4HOURS);
          }}
        >
          <KeyboardArrowRightRounded />
        </IconButton>
      </Stack>
    );
  };
  
  export const DateTimeDoubleSelector = ({ value, onChange }) => {
    return (
      <Stack direction={"row"} alignItems={"center"}>
        <IconButton
          sx={{ display: "inline-flex" }}
          onClick={() => {
            onChange(value - TWENTY4HOURS);
          }}
        >
          <KeyboardDoubleArrowLeft />
        </IconButton>
        <IconButton
          sx={{ display: "inline-flex" }}
          onClick={() => {
            onChange(value - TWENTY4HOURS / 24);
          }}
        >
          <KeyboardArrowLeftRounded />
        </IconButton>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-in"}>
          <MobileDateTimePicker
            value={moment(value)}
            onAccept={(newValue) => {
              if (newValue) {
                const d1 = newValue.valueOf();
                if (value !== d1) onChange(d1);
              }
            }}
            sx={{ maxWidth: "200px", minWidth: "130px" }}
            closeOnSelect={true}
            format="DD/MM/YYYY hh:mm A"
          />
        </LocalizationProvider>
        <IconButton
          sx={{ display: "inline-flex" }}
          onClick={() => {
            onChange(value + TWENTY4HOURS / 24);
          }}
        >
          <KeyboardArrowRightRounded />
        </IconButton>
        <IconButton
          sx={{ display: "inline-flex" }}
          onClick={() => {
            onChange(value + TWENTY4HOURS);
          }}
        >
          <KeyboardDoubleArrowRightRounded />
        </IconButton>
      </Stack>
    );
  };
  
  export const DateSelector = ({
    value,
    onChange,
    title,
    displayFormat,
    minDate,
    maxDate,
    fullWidth,
  }) => {
    return (
      <Stack direction={"row"} alignItems={"center"}>
        <IconButton
          sx={{ display: "inline-flex" }}
          onClick={() => {
            onChange(value - TWENTY4HOURS);
          }}
        >
          <KeyboardArrowLeftRounded />
        </IconButton>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-in"}>
          <MobileDatePicker
            fullWidth={fullWidth}
            label={title || "Select Date"}
            value={moment(value)}
            onAccept={(newValue) => {
              if (newValue) {
                const d1 = newValue.valueOf();
                if (value !== d1) onChange(d1);
              }
            }}
            sx={
              (fullWidth === true && { width: "100%" }) || {
                maxWidth: "130px",
                minWidth: "130px",
              }
            }
            closeOnSelect={true}
            format={displayFormat || "DD/MM/YYYY"}
            minDate={(minDate && moment(minDate)) || null}
            maxDate={(maxDate && moment(maxDate)) || null}
          />
        </LocalizationProvider>
        <IconButton
          sx={{ display: "inline-flex" }}
          onClick={() => {
            onChange(value + TWENTY4HOURS);
          }}
        >
          <KeyboardArrowRightRounded />
        </IconButton>
      </Stack>
    );
  };
  