import React, { useContext, useState, useEffect } from "react";
import fa from "../firebase";
import {
  signInWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signOut,
  getIdToken,
  signInWithCustomToken,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";

import { CircularProgress } from "@mui/material";

import { dataFetch } from "../utils/data-fetch";
import { Capacitor } from "@capacitor/core";

function getFirebaseAuth() {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(fa, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    return getAuth(fa);
  }
};

const auth = getFirebaseAuth();
const AuthContext = React.createContext();
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);


  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function linkLogin(token) {
    return signInWithCustomToken(auth, token);
  }

  function logout() {
    return signOut(auth);
  }

  function getAccessToken(refresh) {
    return currentUser ? getIdToken(currentUser, refresh) : null;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(true);
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const value = {
    currentUser,
    getAccessToken,
    login,
    linkLogin,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {(loading === false && children) || (
        <CircularProgress
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </AuthContext.Provider>
  );
}
