import {
  Card,
  Stack,
  Typography,
  Button,
  useMediaQuery,
  Box,
  Divider,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRef, useState } from "react";
import { AddNewOrderModal } from "./AddNewOrderModal";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import MenuPopover from "../MenuPopover";
import { Home, Logout } from "@mui/icons-material";

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: <Home />,
    linkTo: "/",
  },
  // {
  //   label: "Profile",
  //   icon: <Person />,
  //   linkTo: "#",
  // },
  // {
  //   label: "logout",
  //   icon: <Logout />,
  //   linkTo: "#",
  // },
];

export const BalanceComp = ({ party }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md")); // Check if screen is md or larger
  const [addOrderModal, setAddOrderModal] = useState(false);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  // const [logoutError, setLogoutError] = useState();

  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();

  const handleLogout = async () => {
    try {
      // setLogoutError(null);
      await logout();
      navigate("/login", {
        replace: true,
      });
    } catch (error) {
      console.log(error);
      // setLogoutError(error.code);
      alert(error.code);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (!party) {
    return null; // Or return a loading state, depending on your needs
  }

  return (
    <Card
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: 1,
        bgcolor: "grey.200",
        width: "100%", // 100% width for all screen sizes
        mx: 2,
      }}
    >
      <Stack
        direction={isMdUp ? "row" : "column"} // Row on md and up, column on smaller
        justifyContent="space-between"
        alignItems={isMdUp ? "center" : "flex-start"} // Align based on screen size
        spacing={3}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <img
            src={"/image.png"}
            alt="avatar"
            ref={anchorRef}
            style={{ width: 60, height: 60 }}
            onClick={handleOpen}
          />

          <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{
              width: 220,
            }}
          >
            {currentUser && (
              <Box
                sx={{
                  my: 1.5,
                  px: 2.5,
                }}
              >
                {currentUser?.name && (
                  <Typography variant="subtitle1" noWrap>
                    <b>{currentUser.name}</b>
                  </Typography>
                )}
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                  }}
                  noWrap
                >
                  {currentUser?.phone}
                </Typography>
              </Box>
            )}
            <Divider
              sx={{
                my: 1,
              }}
            />
            {MENU_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                //component={RouterLink}
                onClick={handleClose}
                sx={{
                  typography: "body2",
                  py: 1,
                  px: 2.5,
                }}
              >
                <Box
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24,
                  }}
                >
                  {option.icon}
                </Box>
                {option.label}
              </MenuItem>
            ))}
            <Box
              sx={{
                p: 2,
                pt: 1.5,
              }}
            >
              <Button
                fullWidth
                color="inherit"
                variant="outlined"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Box>
          </MenuPopover>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
            Hi, {party.name}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent={isMdUp ? "center" : "flex-start"}
          spacing={1}
        >
          <Typography variant="h6" sx={{ fontWeight: "medium" }}>
            Balance:
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: party.balance > 0 ? "green" : "red",
            }}
          >
            {party.balance}
          </Typography>
        </Stack>

        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            mt: { xs: 2, md: 0 },
            ml: { md: 2 },
            alignSelf: { xs: "center", md: "flex-end" },
            borderRadius: 3,
            fontWeight: "bold",
            textTransform: "none",
          }}
          onClick={() => setAddOrderModal(true)}
        >
          Order Now
        </Button>
      </Stack>

      {addOrderModal && (
        <AddNewOrderModal
          open={addOrderModal}
          party={party}
          handleM1Close={() => setAddOrderModal(false)}
        />
      )}
    </Card>
  );
};
