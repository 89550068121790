import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { dataFetch } from "../utils/data-fetch";
import { BalanceComp } from "../components/account/BalanceComp";
import { Stack } from "@mui/material";
import { OngoingOrderComp } from "../components/account/OngoingOrderComp";
import { PastOrderComp } from "../components/account/PastOrdersComp";
import Footer from "../components/account/Footer";
import { PaymentComp } from "../components/account/PaymentComp";
import Page from "../components/page";

export default function B2bDashBoard() {
  const [party, setParty] = useState(null);
  const [error, setError] = useState(null);

  const { getAccessToken } = useAuth();

  const loadData = async () => {
    const url = `/api/extrasales/b2bact/profile`;

    const ret = await dataFetch(url, await getAccessToken());

    if (ret.data) {
      setParty(ret.data);
      console.log("party", party);
    } else {
      setError(ret.error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Page title="Bulk milk delivery">
      <Stack direction="column" spacing={1}>
        <BalanceComp party={party} />
        <PaymentComp />
        <OngoingOrderComp party={party} />
        <PastOrderComp party={party} />
        <Footer party={party} />
      </Stack>
    </Page>
  );
}
