import MHidden from "../@material-extend/MHidden";

const { Stack, Typography, Box, Divider, Button } = require("@mui/material");
const { default: MakeFullScreen } = require("../MakeFullScreen");

const AboutUs = ({ onOpen, onClose }) => {
  return (
    <MakeFullScreen
      open={onOpen}
      onClose={onClose}
      title="About Us"
      fullScreen={true}
    >
      <>
        <MHidden width="mdUp">
          <>
            <Stack direction="column" spacing={2} p={2}>
              <img
                src="/static/cow.png"
                alt="Milkvilla Logo"
                style={{ width: "100%", height: "auto" }}
              />

              <Box>
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  Milkvilla is a hyperlocal supply chain network with direct
                  access to primary producers and consumers.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  We are a decentralized network of farmers and consumers where
                  primarily milk collection and delivery happen at the doorstep
                  in non-packaged form.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  We have completely digitalized the whole milk supply chain and
                  drastically reduced leakage and processing with the help of
                  technology. We are currently based out of Muzaffarpur district
                  in Bihar.
                </Typography>
                <br />

                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  We are expanding pan-india using owned and franchised centers.
                </Typography>
              </Box>
            </Stack>
            <Box
              sx={{
                width: "100%",
                bottom: 0,
                py: 4,
                px: 3,
                textAlign: "center",
                alignItems: "center",
                display: "flex", // Flexbox to center the content
                flexDirection: "column", // Stack the items vertically
                justifyContent: "center", // Center content horizontally
                boxShadow: 2,
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px", // Optional: for rounded corners
                    width: 60, // Slightly larger for padding
                    height: 60,
                  }}
                >
                  <img
                    src="/static/fssai.png"
                    alt="fssai"
                    style={{ width: 50, height: 50 }}
                  />
                </Box>
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  11224333001363
                </Typography>
              </Stack>
              <Typography variant="body1" sx={{ fontWeight: 400, mt: 1 }}>
                GSTIN: 29AAOCM2988C1ZZ
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                bottom: 0,
                color: "white",
                bgcolor: "#FFEB3B",
                py: 4, // Increased padding for more space
                px: 3,
                textAlign: "center", // Center the content
                boxShadow: 2,
              }}
            >
              <Stack spacing={2} direction="column" alignItems="center">
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, color: "textPrimary" }}
                >
                  Find us
                </Typography>

                <Divider sx={{ width: "40%", fontWeight: "600" }} />

                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Milkvilla, Off Sarjapur Road, Near HN Halli Lake, Banglore -
                  35
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, color: "textPrimary" }}
                >
                  Email us
                </Typography>

                <Divider sx={{ width: "40%", fontWeight: "600" }} />

                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  contact@milkvilla.in
                </Typography>

                <Divider sx={{ width: "100%" }} />

                <Stack direction="row" spacing={2}>
                  <Button
                    sx={{ textTransform: "none", fontWeight: 400 }}
                    onClick={() => {
                      //window.open("/about", "_self");
                    }}
                  >
                    Privacy
                  </Button>

                  <Button
                    sx={{ textTransform: "none", fontWeight: 400 }}
                    // onClick={() => {
                    //   //window.open("/contact", "_self");
                    // }}
                  >
                    Cookie
                  </Button>

                  <Button
                    sx={{ textTransform: "none", fontWeight: 400 }}
                    onClick={() => {
                      //window.open("/faq", "_self");
                    }}
                  >
                    Terms
                  </Button>

                  <Button
                    sx={{ textTransform: "none", fontWeight: 400 }}
                    onClick={() => {
                      //window.open("/faq", "_self");
                    }}
                  >
                    Refund
                  </Button>

                  <Button
                    sx={{ textTransform: "none", fontWeight: 400 }}
                    onClick={() => {
                      //window.open("/faq", "_self");
                    }}
                  >
                    Pricing
                  </Button>
                </Stack>

                <Divider sx={{ width: "100%" }} />

                <Typography variant="body2" sx={{ fontWeight: 400, mt: 2 }}>
                  Milkvilla©2024, All Rights Reserved
                </Typography>
              </Stack>
            </Box>
          </>
        </MHidden>
        <>
          <MHidden width="smDown">
            <Stack
              direction="row"
              spacing={2}
              p={2}
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center", // Center the content horizontally
                maxWidth: "1300px",
                margin: "0 auto",
                padding: { xs: 4, md: 10 },
              }}
            >
              <img
                src="/static/cow.png"
                alt="Milkvilla Logo"
                style={{ width: "40%", height: "auto" }}
              />

              <Box
                sx={{
                  textAlign: "left",
                  maxWidth: "600px", // Limit text width for readability
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  Milkvilla is a hyperlocal supply chain network with direct
                  access to primary producers and consumers.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  We are a decentralized network of farmers and consumers where
                  primarily milk collection and delivery happen at the doorstep
                  in non-packaged form.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  We have completely digitalized the whole milk supply chain and
                  drastically reduced leakage and processing with the help of
                  technology. We are currently based out of Muzaffarpur district
                  in Bihar.
                </Typography>
                <br />
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  We are expanding pan-India using owned and franchised centers.
                </Typography>
              </Box>
            </Stack>

            <Box
              sx={{
                width: "100%",
                bottom: 0,
                py: 4,
                px: 3,
                textAlign: "center",
                alignItems: "center",
                display: "flex", // Flexbox to center the content
                flexDirection: "column", // Stack the items vertically
                justifyContent: "center", // Center content horizontally
                boxShadow: 2,
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px", // Optional: for rounded corners
                    width: 60, // Slightly larger for padding
                    height: 60,
                  }}
                >
                  <img
                    src="/static/fssai.png"
                    alt="fssai"
                    style={{ width: 50, height: 50 }}
                  />
                </Box>
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  11224333001363
                </Typography>
              </Stack>
              <Typography variant="body1" sx={{ fontWeight: 400, mt: 1 }}>
                GSTIN: 29AAOCM2988C1ZZ
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                bgcolor: "#FFEB3B",
                color: "white",
                py: 4,
                px: 3,
                boxShadow: 2,
                mt: 4, // Add some space above the footer
              }}
            >
              <Stack spacing={3} alignItems="center" textAlign="center">
                {" "}
               
                <Stack
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    width: "80%",
                    maxWidth: "1000px", 
                    mx: "auto", 
                  }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, color: "textPrimary" }}
                    >
                      Find us
                    </Typography>
                    <Divider sx={{ width: "40%", fontWeight: 600 }} />
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      Milkvilla, Off Sarjapur Road, Near HN Halli Lake,
                      Bangalore - 35
                    </Typography>
                  </Stack>

                  <Stack direction="column">
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, color: "textPrimary" }}
                    >
                      Call us
                    </Typography>
                    <Divider sx={{ width: "40%", fontWeight: 600 }} />
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      +91-9006890881
                    </Typography>
                  </Stack>

                  <Stack direction="column">
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, color: "textPrimary" }}
                    >
                      Email us
                    </Typography>
                    <Divider sx={{ width: "40%", fontWeight: 600 }} />
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      contact@milkvilla.in
                    </Typography>
                  </Stack>
                </Stack>
                <Divider sx={{ width: "100%" }} />
                <Stack
                  width={"100%"}
                  direction="row"
                  spacing={2}
                  justifyContent={"space-between"}
                >
                  <Typography variant="body2" sx={{ fontWeight: 400, pt:1 }}>
                    Milkvilla©2024, All Rights Reserved
                  </Typography>

                  <Stack direction="row" spacing={2}>
                    <Button
                      sx={{ textTransform: "none", fontWeight: 400 }}
                      onClick={() => {
                        //window.open("/about", "_self");
                      }}
                    >
                      Privacy
                    </Button>

                    <Button
                      sx={{ textTransform: "none", fontWeight: 400 }}
                      // onClick={() => {
                      //   //window.open("/contact", "_self");
                      // }}
                    >
                      Cookies
                    </Button>

                    <Button
                      sx={{ textTransform: "none", fontWeight: 400 }}
                      onClick={() => {
                        //window.open("/faq", "_self");
                      }}
                    >
                      Terms
                    </Button>

                    <Button
                      sx={{ textTransform: "none", fontWeight: 400 }}
                      onClick={() => {
                        //window.open("/faq", "_self");
                      }}
                    >
                      Refund
                    </Button>

                    <Button
                      sx={{ textTransform: "none", fontWeight: 400 }}
                      onClick={() => {
                        //window.open("/faq", "_self");
                      }}
                    >
                      Pricing
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </MHidden>
        </>
      </>
    </MakeFullScreen>
  );
};

export default AboutUs;
