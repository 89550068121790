import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
  Button,
  Stack,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { dataFetch } from "../../utils/data-fetch";
import MHidden from "../@material-extend/MHidden";

export const OngoingOrderComp = ({ party }) => {
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { getAccessToken } = useAuth();

  const loadData = async (party) => {
    setLoading(true);

    const url = `/api/extrasales/b2bact/orders/ongoing?id=${party?._id}`;

    try {
      const ret = await dataFetch(url, await getAccessToken());
      if (ret.data) {
        setOrders(ret.data);
      } else {
        setError(ret.error || "Failed to fetch data");
      }
    } catch (err) {
      setError("An unexpected error occurred");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (party?._id) {
      loadData(party); // Only call the API when party._id is defined
    }
  }, [party]);

  const handleRetry = () => {
    loadData(party); // Re-fetch data on retry
  };

  const TABLE_HEAD = [
    { id: "product", label: "Product" },
    { id: "order_amount", label: "Order Amount" },
    { id: "qty", label: "Quantity" },
    { id: "status", label: "Status" },
  ];

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box sx={{ textAlign: "center", p: 2 }}>
          <Typography color="error">{error}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRetry}
            sx={{ mt: 2 }}
          >
            Retry
          </Button>
        </Box>
      ) : orders && orders.length > 0 ? (
        <>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Ongoing Orders
          </Typography>

          <MHidden width="smUp">
            <Stack spacing={1}>
              {orders.map((order, index) => (
                <Box
                  key={order._id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                    borderRadius: 2,
                    bgcolor: index % 2 === 0 ? "grey.50" : "white",
                    boxShadow: 1,
                    transition: "0.3s",
                    "&:hover": {
                      bgcolor: "grey.100",
                      boxShadow: 3,
                    },
                  }}
                >
                  <Stack direction="row" justifyContent="space-between">
                    {/* Image of the product */}
                    <img
                      src={order?.image || "/static/icon2.png"}
                      alt={order.item}
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 8,
                        objectFit: "cover",
                        marginRight: 16,
                      }}
                    />
                    <Stack
                      direction="column"
                      justifyContent="center"
                      flexGrow={1}
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" color="textSecondary">
                          {order.item}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          {order.status}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        mt={1}
                      >
                        <Typography variant="body2" color="textPrimary">
                          {order.quantity} KG
                        </Typography>
                        <Typography variant="body2" color="textPrimary">
                          ₹{order.amount}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </MHidden>
          <MHidden width="smDown">
            <TableContainer>
              <Table>
                <TableHead sx={{ bgcolor: "grey.200" }}>
                  <TableRow>
                    {TABLE_HEAD.map((head) => (
                      <TableCell
                        key={head.id}
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          py: 1.5,
                        }}
                      >
                        {head.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order, index) => (
                    <TableRow
                      key={order._id}
                      sx={{
                        bgcolor: index % 2 === 0 ? "grey.50" : "white",
                        "&:hover": { bgcolor: "grey.100" },
                      }}
                    >
                      <TableCell sx={{ textAlign: "center", py: 1.5 }}>
                        {order.item}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", py: 1.5 }}>
                        {order.amount}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", py: 1.5 }}>
                        {order.quantity} KG
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", py: 1.5 }}>
                        {order.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MHidden>
        </>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            p: 4,
            bgcolor: "grey.100",
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 1 }}>
            No Ongoing Orders
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            There are no ongoing orders at the moment. Please check back later.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleRetry}>
            Recheck
          </Button>
        </Box>
      )}
    </Paper>
  );
};
